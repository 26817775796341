import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDate.css";
import axios from "axios";
import toast from "react-hot-toast";
import { backend_url } from "../../url";
const Calendar = ({
  id,
  setCurrentDueDate,
  currentDueDate,
  selectedPaymentMode,
}) => {
  const [dueDate, setDueDate] = useState(
    currentDueDate !== null ? currentDueDate : new Date()
  );

  const handleUpdateDate = async () => {
    try {
      const response = await axios.put(
        `${backend_url}/api/order/updateDueDate/${id}`,
        {
          dueDate,
          selectedPaymentMode,
        }
      );
      if (response.status === 200) {
        toast.success("Due date updated successfully!");
      }
      console.log(response);
    } catch (error) {
      toast.error("Some thing went wrong!");
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="flex">
        <div className="mr-3 text-xl font-bold">Due Date:</div>
        <div className="custom-datepicker mr-3 relative">
          <DatePicker
            selected={dueDate}
            onChange={(date) => {
              setDueDate(date);
              setCurrentDueDate(date);
            }}
          />
          <div className="absolute right-3 top-3 pointer-events-none">
            <FaCalendarAlt />
          </div>
        </div>
        <button
          onClick={handleUpdateDate}
          className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800  focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update Date
        </button>
      </div>
    </>
  );
};

export default Calendar;
