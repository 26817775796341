// import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import { backend_url } from "../url";
import toast from "react-hot-toast";
import Eye from "../components/Icon/Eye";
import EyeClosed from "../components/Icon/EyeClosed";
import { TbLoader2 } from "react-icons/tb";
function Login() {
  const API = backend_url;
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const authCheck = () => {
    setLoading(true);
    fetch(`${API}/api/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("user", JSON.stringify(data));
        toast.success("Successfully logged in");
        setLoading(false);
        authContext.signin(data._id, () => {
          if (data.role === "Admin") {
            navigate("/");
          } else if (data.role === "Sales") {
            navigate("/sales-dashboard");
          } else if (data.role === "CustomerCare") {
            navigate("/customercare-dashboard");
          } else {
            navigate("/login");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong!");
        console.log("Error:", err);
      });
  };

  const loginUser = (e) => {
    e.preventDefault();

    if (form.email === "" || form.password === "") {
      toast.error("To login user, enter details to proceed..");
    } else {
      authCheck();
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 h-screen  items-center place-items-center">
        <div className="flex justify-center">
          <img src={process.env.PUBLIC_URL + "/assets/signup.jpg"} alt="" />
        </div>
        <div className="w-full max-w-md space-y-8 p-10 rounded-lg">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={process.env.PUBLIC_URL + "/assets/adhrija-logo.png"}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={loginUser}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                  value={form.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="relative">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className="relative block w-full rounded-b-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Password"
                  value={form.password}
                  onChange={handleInputChange}
                />
                {showPassword ? (
                  <div
                    className="absolute right-[16px] top-[11px]  cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Eye />
                  </div>
                ) : (
                  <div
                    className="absolute right-[16px] top-[8px]  cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <EyeClosed />
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* Uncomment these if needed */}
              {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div> */}

              {/* <div className="text-sm">
                <span
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </span>
              </div> */}
            </div>

            <div>
              <button
                disabled={loading}
                type="submit"
                className={`
                  group relative flex w-full justify-center rounded-md py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                  ${loading ? "bg-indigo-500" : "bg-indigo-600"}`}
              >
                {loading ? (
                  <div className="animate-spin">
                    <TbLoader2 size={24} />
                  </div>
                ) : (
                  "Sign in"
                )}
              </button>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{" "}
                <span className="font-medium text-indigo-600 hover:text-indigo-500">
                  Don&apos;t Have an Account? Please{" "}
                  <Link to="/register"> Register now </Link>
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
