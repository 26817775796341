import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backend_url } from "../url";
import toast from "react-hot-toast";

function OrderDetailsForSales() {
  const API = backend_url;
  const { id } = useParams();
  const [orders, setOrders] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editedQuantities, setEditedQuantities] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setAllProducts] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchOrderData(id);
  }, [id]);

  useEffect(() => {
    fetchProductsData();
  }, []);

  const fetchOrderData = (id) => {
    fetch(`${API}/api/order/get/${id}`) // Replace with the correct API endpoint
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        initializeEditedQuantities(data);
      })
      .catch((err) => console.log(err));
  };

  // Fetching Data of All Products
  const fetchProductsData = () => {
    fetch(`${API}/api/product/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  const initializeEditedQuantities = (orderData) => {
    const initialQuantities = {};
    orderData.cartItems.forEach((cartItem) => {
      initialQuantities[cartItem.productId[0]._id] = cartItem.quantity;
    });
    setEditedQuantities(initialQuantities);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddProductClick = () => {
    setIsAddingProduct(true);
  };

  const handleCloseModal = () => {
    setIsAddingProduct(false);
    // Reset selected product if needed
    setSelectedProduct(null);
  };

  // const handleSaveEdit = () => {
  //   // You can send a request to your backend API to update the quantities
  //   // For simplicity, let's just update the local state
  //   const updatedCartItems = orders.cartItems.map((cartItem) => ({
  //     ...cartItem,
  //     quantity: editedQuantities[cartItem.productId[0]._id],
  //   }));

  //   setOrders((prevOrders) => ({
  //     ...prevOrders,
  //     cartItems: updatedCartItems,
  //   }));

  //   // After updating, set isEditing to false to exit the editing mode
  //   setIsEditing(false);
  // };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    // Prepare the data to be sent to the backend
    const updatedQuantities = Object.entries(editedQuantities).map(
      ([productId, quantity]) => ({
        productId,
        quantity,
      })
    );

    // Send a PUT request to update the order in the backend
    fetch(`${API}/api/order/updateorderproduct/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cartItems: updatedQuantities }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update local state with the response from the backend if needed
        setOrders(data);
        // Set isEditing to false to exit the editing mode
        setIsEditing(false);
        toast.success("order updated successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setEditedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const handleAddProductToOrder = async (product) => {
    try {
      const response = await fetch(
        `${API}/api/order/addproducttoexistingorder/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cartItems: [{ productId: product._id, quantity: 0 }],
          }),
        }
      );

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrders(updatedOrder);
        toast.success("Product added to order successfully");
        handleCloseModal();
        window.location.reload();
      } else {
        toast.error("Failed to add product to order");
      }
    } catch (error) {
      console.error(error);
      toast.error("Server error");
    }
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-10 flex-col  flex justify-center">
        <div className=" flex flex-col gap-5 w-full">
          {/* Table  */}
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-6">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center ">
                <span className="font-bold">Recieved Orders</span>
                <div className="flex justify-center items-center px-2 border-2 rounded-md hidden">
                  <img
                    alt="search-icon"
                    className="w-5 h-5"
                    src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                  />
                  <input
                    className="border-none outline-none focus:border-none text-xs"
                    type="text"
                    placeholder="Search here"
                    //   value={searchTerm}
                    //   onChange={handleSearchTerm}
                  />
                </div>
              </div>
              {/* <div className="flex gap-4">
              
            </div> */}
            </div>
            {orders && (
              <div className="w-11/12 my-4">
                <h1 className="text-2xl font-bold">Customer Details:</h1>
                <div className="flex flex-col gap-2">
                  {/* <strong className="font-bold">Customer Details:</strong> */}
                  {orders.customer.map((customerId) => (
                    // Assuming you have a separate API endpoint to fetch customer details
                    // Modify the API endpoint accordingly
                    <div className="flex flex-col" key={customerId}>
                      {/* Fetch and display customer details here */}
                      {/* Example: */}
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">Customer ID:</h5>{" "}
                        {customerId.customerID}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Name:
                        </h5>{" "}
                        {customerId.customerName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Phone Number:
                        </h5>{" "}
                        {customerId.customerPhoneNumber}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Store Name:
                        </h5>{" "}
                        {customerId.storeName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Pincode:
                        </h5>{" "}
                        {customerId.pincode}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Address:
                        </h5>{" "}
                        {customerId.Address}
                      </div>
                      {/* Add more customer details as needed */}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {orders && (
              <div className="w-11/12">
                <h1 className="text-2xl font-bold my-4">
                  Order ID - {orders.orderID}
                </h1>
                <p className="text-sm mb-3 flex ">
                  Order Current Status:{" "}
                  <p className="pl-4 font-bold">{orders.status}</p>
                </p>
                <p className="text-sm mb-3 flex ">
                  Order Payment Status:{" "}
                  <p className="pl-4 font-bold">{orders.paymentstatus}</p>
                </p>
                <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                        Product Name
                      </th>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {orders &&
                      orders.cartItems.map((cartItem, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                            {/* {cartItem.productId[0].name} Assuming 'name' is the property for the product name */}
                            {cartItem.productId.map((product) => product.name)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            {cartItem.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {orders && orders.status === "processing" && (
              <>
                <button
                  onClick={handleEditClick}
                  className="bg-blue-500 text-white px-4 py-2 mr-4 mt-4 rounded-md"
                >
                  Edit
                </button>
                <button
                  onClick={handleAddProductClick}
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                >
                  Add Product
                </button>
              </>
            )}
            {isEditing && (
              <div>
                {/* Modal for editing quantities */}
                <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                  <div className="bg-white p-8 rounded-md">
                    <h2 className="text-2xl font-bold mb-4">Edit Quantities</h2>
                    <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {orders.cartItems.map((cartItem) => (
                          <tr key={cartItem.productId[0]._id}>
                            <td>{cartItem.productId[0].name}</td>
                            <td>
                              <input
                                type="number"
                                value={
                                  editedQuantities[cartItem.productId[0]._id]
                                }
                                onChange={(e) =>
                                  handleQuantityChange(
                                    cartItem.productId[0]._id,
                                    parseInt(e.target.value, 10)
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button
                      onClick={handleSaveEdit}
                      className="bg-blue-500 text-white px-4 py-2 pr-4 mr-4 mt-4 rounded-md"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelEdit}
                      className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* {isAddingProduct && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-8 rounded-md">
      <h2 className="text-2xl font-bold mb-4">Add Product to Order</h2>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            {product.name}
            <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={() => handleAddProductToOrder(product)}>
              Add to Order
            </button>
          </li>
        ))}
      </ul>
      <button onClick={handleCloseModal} className="bg-red-500 text-white px-4 py-2 mt-4 rounded-md">
        Cancel
      </button>
    </div>
  </div>
)} */}

            {isAddingProduct && (
              <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-8 rounded-md">
                  <h2 className="text-2xl font-bold mb-4">
                    Add Product to Order
                  </h2>

                  {/* Search input */}
                  <input
                    type="text"
                    placeholder="Search Product name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-4 px-4 py-2 rounded-md border"
                  />

                  <table className="w-full">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredProducts.map((product) => (
                        <tr key={product._id}>
                          <td>{product.name}</td>
                          <td>
                            <button
                              className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => handleAddProductToOrder(product)}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    onClick={handleCloseModal}
                    className="bg-red-500 text-white px-4 py-2 mt-4 rounded-md"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-span-12 lg:col-span-10 m-auto flex justify-items-end ">
      <div className=" flex flex-col w-full gap-5">
       <div className="rounded-lg border bg-white border-gray-200 ">

         <CartPage cartItems={cartItems} />
       </div>
      </div>
    </div> */}
    </>
  );
}

export default OrderDetailsForSales;
