import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../AuthContext";
import { backend_url } from "../url";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

function CartPage({ cartItems }) {
  const API = backend_url;
  const authContext = useContext(AuthContext);
  const orderId = generateUniqueNumericId(10);
  const navigate = useNavigate();

  const [customer, setCustomer] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [orderedcartItems, setOrderedCartItems] = useState([]);
  const [quantity, setQuantity] = useState({});
  // const [portal, setPortal] = useState("");
  const [platform, setPlatform] = useState("Portal");
  const [platforms, setPlatForms] = useState([
    { id: "Amazon", text: "Amazon" },
    { id: "Jio Mart", text: "Jio Mart" },
    { id: "Website", text: "Website" },
    { id: "Portal", text: "Portal" },
  ]);

  // Load cart items from localStorage on component mount
  useEffect(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    if (savedCartItems) {
      setOrderedCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  useEffect(() => {
    fetchCustomerData();
  }, []);

  //   // Fetching Data of All Purchase items
  const fetchCustomerData = () => {
    fetch(`${API}/api/customer/get`)
      .then((response) => response.json())
      .then((data) => {
        setCustomers(data);
      })
      .catch((err) => console.log(err));
  };

  const handleQuantityChange = (itemId, event) => {
    const updatedQuantity = { ...quantity };
    updatedQuantity[itemId] = parseInt(event.target.value);
    setQuantity(updatedQuantity);
    updateCart(updatedQuantity);
  };

  const handleDeleteItem = (itemId) => {
    const updatedCartItems = orderedcartItems.filter(
      (item) => item._id !== itemId
    );
    setOrderedCartItems(updatedCartItems);
    updateCartItemsInLocalStorage(updatedCartItems);
  };

  const updateCart = (updatedQuantity) => {
    // Update the quantity in the cart and save it to localStorage
    const updatedCart = orderedcartItems.map((item) => ({
      ...item,
      quantity: updatedQuantity[item._id] || 0,
    }));
    setOrderedCartItems(updatedCart);
    updateCartItemsInLocalStorage(updatedCart);
  };

  const updateCartItemsInLocalStorage = (updatedCartItems) => {
    // Save the updated cart items to localStorage
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  function generateUniqueNumericId(length) {
    let result = "#";
    for (let i = 0; i < length; i++) {
      result += Math.floor(Math.random() * 10); // Generate a random digit (0-9)
    }
    return result;
  }

  const onChange = (value) => {
    setCustomer(value);
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();

    const selectedCustomer = customers.find(
      (element) => element._id === customer
    );
    // Prepare the data to send in the POST request
    const orderData = {
      orderId,
      userId: authContext.user,
      customer: selectedCustomer,
      platform,
      cartItems: orderedcartItems.map((item) => ({
        productId: [item._id],
        quantity: quantity[item._id] || 0,
      })),
    };

    fetch(`${API}/api/order/place-order`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((result) => {
        alert("Order successfull");
        // Clear the local storage
        localStorage.removeItem("cartItems");
        navigate("/sales-dashboard");

        // Refresh the page
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Added Products</span>
            </div>
          </div>

          {/* <div className="cart">
            <h2>Cart</h2>
            <ul>
              {cartItems &&
                cartItems.map((item, index) => (
                  <li key={index}>
                    {item.name}
                    <input
                      type="number"
                      // min="1"
                      value={quantity[item._id]}
                      onChange={(e) => handleQuantityChange(item._id, e)}
                    />
                  </li>
                ))}
            </ul>
          </div> */}
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Product
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Available Stock
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Quantity
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {orderedcartItems &&
                  orderedcartItems.map((item, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                        {item.stock}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 ">
                        <input
                          type="number"
                          // min="1"
                          value={quantity[item._id]}
                          onChange={(e) => handleQuantityChange(item._id, e)}
                          className="border-opacity-25 rounded"
                          required
                        />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <button
                          onClick={() => handleDeleteItem(item._id)}
                          className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-wrap min-[320px]:flex-none max-[600px]:flex-none justify-between pt-5 pb-3 px-3">
            <div className="flex min-[320px]:flex-none max-[600px]:flex-none  gap-4 justify-center items-center">
              <span className="font-bold">
                Select a Customer to place order:
              </span>
              {/* <select
                className="rounded-lg"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              >
                <option value="">Select a Customer</option>
                {customers &&
                  customers.map((element) => (
                    <option key={element._id} value={element._id}>
                      {element.customerID}
                    </option>
                  ))}
              </select> */}
              <Select
                className="w-96 h-10"
                showSearch
                placeholder="Search and Select a Customer"
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={customers.map((element) => ({
                  value: `${element._id}`,
                  label: `${element.customerID} - ${element.customerPhoneNumber}- ${element.storeName} - (${element.pincode})`,
                }))}
                value={customer}
              />
            </div>
            {/* <input type="hidden" value="Portal" id="portal" /> */}
            <select
              // className="rounded-lg"
              className={`rounded-lg ${platform === "Portal" ? "hidden" : ""}`}
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              <option value="">Select a Platform</option>
              {platforms &&
                platforms.map((element) => (
                  <option key={element._id} value={element._id}>
                    {element.text}
                  </option>
                ))}
            </select>
            <div className="flex gap-4 justify-center items-center w-10/12">
              <button
                onClick={handlePlaceOrder}
                className=" bg-blue-500 hover:bg-blue-600 text-white py-2 rounded w-3/12 mt-6"
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartPage;
