import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../AuthContext";
import AddCustomers from "../components/AddCustomers";
import { backend_url } from "../url";
import Pagination from "react-js-pagination";

function AdminCustomers() {
  const API = backend_url;
  const [showCustomerModal, setCustomerModal] = useState(false);
  const [customers, setAllCustomerData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [updatePage, setUpdatePage] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(10);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchCustomerData();
  }, [updatePage]);

  //   // Fetching Data of All Purchase items
  const fetchCustomerData = () => {
    fetch(`${API}/api/customer/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllCustomerData(data);
      })
      .catch((err) => console.log(err));
  };

  // Modal for Sale Add
  const addCustomerModalSetting = () => {
    setCustomerModal(!showCustomerModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  const formatAddress = (address) => {
    const words = address.split(" ");
    const chunks = [];
    for (let i = 0; i < words.length; i += 4) {
      chunks.push(words.slice(i, i + 4).join(" "));
    }
    return chunks.join("\n");
  };

  useEffect(() => {
    // Filter orders based on search term
    const filtered =
      customers &&
      customers.filter(
        (element) =>
          String(element.customerID)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          String(element.customerName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          String(element.customerPhoneNumber).includes(searchTerm) ||
          String(element.pincode).includes(searchTerm) ||
          String(element.storeName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          String(element.Address)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          String(element.platform)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    setFilteredOrders(filtered);
  }, [searchTerm, customers]);

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredOrders.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {showCustomerModal && (
          <AddCustomers
            addCustomerModalSetting={addCustomerModalSetting}
            handlePageUpdate={handlePageUpdate}
            authContext={authContext}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Customer Details</span>
              <div className="flex justify-center items-center px-2 border-2 rounded-md">
                <img
                  alt="search-icon"
                  className="w-5 h-5"
                  src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full h-full px-2 border-none outline-none"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addCustomerModalSetting}
              >
                {/* <Link to="/inventory/add-product">Add Product</Link> */}
                Add customer
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3 hidden">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Customer Details</span>
              <div className="flex justify-center items-center px-2 border-2 rounded-md">
                <img
                  alt="search-icon"
                  className="w-5 h-5"
                  src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full h-full px-2 border-none outline-none"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addCustomerModalSetting}
              >
                {/* <Link to="/inventory/add-product">Add Product</Link> */}
                Add customer
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Customer ID
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Customer Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Store Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Phone Number
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Pincode
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Address
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {currentCustomers.map((element) => {
                return (
                  <tr key={element._id}>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.customerID}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.customerName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.storeName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.customerPhoneNumber}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.pincode}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <pre className="whitespace-pre-line">
                        {formatAddress(element.Address)}
                      </pre>
                    </td>
                    {/* <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {new Date(element.PurchaseDate).toLocaleDateString() ==
                      new Date().toLocaleDateString()
                        ? "Today"
                        : element.PurchaseDate}
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end mt-4">
            {/* Pagination component */}
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={customersPerPage}
              totalItemsCount={filteredOrders.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              activeClass="z-10 bg-indigo-500 text-white border-indigo-500"
              itemClassFirst="mr-0"
              itemClassPrev="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
              itemClassNext="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
              itemClassLast="ml-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminCustomers;
