import React from "react";
import { Outlet } from "react-router-dom";
import CustomerHeader from "./CustomerHeader";
import CustomerSideMenu from "./CustomerSideMenu";
import LowstockProducts from "./LowstockProducts";

function CustomerCareLayout() {
  return (
    <>
      <div className="md:h-16">
        <CustomerHeader />
      </div>
      <div className="grid grid-cols-12 bg-gray-100 items-baseline">
        <div className="col-span-2 h-screen sticky top-0 hidden lg:flex">
          <CustomerSideMenu />
        </div>
        <LowstockProducts />
        <Outlet />
      </div>
    </>
  );
}

export default CustomerCareLayout;
