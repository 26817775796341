import AuthContext from "./AuthContext";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { backend_url } from "./url";

function ProtectedWrapper(props) {
  const API = backend_url;
  const auth = useContext(AuthContext);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // If the user is authenticated (you might have a token or user ID)
    if (auth.user) {
      // Make an API request to fetch the user's role from your backend
      fetch(`${API}/api/user/get/${auth.user}`)
        .then((response) => response.json())
        .then((data) => {
          setUserRole(data.role); // Assuming you receive the user's role in the response
        })
        .catch((error) => {
          console.error("Error fetching user role:", error);
          setUserRole(null);
        });
    }
  }, [auth.user, API]);

  if (!auth.user) {
    return <Navigate to="/login" replace />;
  }

  // Check the user's role and conditionally render or restrict access
  if (userRole === "Admin" && props.requiredRole === "Admin") {
    // Render or restrict access for admin
    return props.children;
  } else if (userRole === "Sales" && props.requiredRole === "Sales") {
    // Render or restrict access for sales manager
    return props.children;
  } else if (
    userRole === "CustomerCare" &&
    props.requiredRole === "CustomerCare"
  ) {
    return props.children;
  } else {
    // Render unauthorized message for unknown roles
    return props.children;
    // return (
    //   <div className="text-center">
    //     <h1 className="text-red-500">Unauthorized</h1>
    //     <p>you can't access this page without login</p>
    //     <Link to="/login">
    //       <button className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">
    //         Login
    //       </button>
    //     </Link>
    //   </div>
    // );
  }

  // return props.children;
}
export default ProtectedWrapper;
