import React from "react";
import { Link } from "react-router-dom";

function CustomerSideMenu() {
  const localStorageData = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="h-full flex-col justify-between  bg-white hidden lg:flex ">
      <div className="px-4 py-6">
        <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
          <Link
            to="/customercare-dashboard"
            className="flex items-center gap-2 rounded-lg hover:bg-gray-100 px-4 py-2 text-gray-700"
          >
            <img
              alt="dashboard-icon"
              src={process.env.PUBLIC_URL + "/assets/dashboard-icon.png"}
            />
            <span className="text-sm font-medium"> Dashboard </span>
          </Link>
          <Link
            to="/allorders"
            className="flex items-center gap-2 rounded-lg hover:bg-gray-100 px-4 py-2 text-gray-700"
          >
            <img
              alt="dashboard-icon"
              src={process.env.PUBLIC_URL + "/assets/my-order.png"}
            />
            <span className="text-sm font-medium"> Orders </span>
          </Link>
          {/* <Link
            to="/order"
            className="flex items-center gap-2 rounded-lg hover:bg-gray-100 px-4 py-2 text-gray-700"
          >
            <img
              alt="dashboard-icon"
              src={process.env.PUBLIC_URL + "/assets/dashboard-icon.png"}
            />
            <span className="text-sm font-medium"> Order </span>
          </Link> */}

          {/* <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
              <Link to="/order">
                <div className="flex items-center gap-2">
                  <img
                    alt="inventory-icon"
                    src="/assets/inventory-icon.png"
                  />
                  <span className="text-sm font-medium"> Order </span>
                </div>
              </Link>
            </summary>
          </details> */}

          {/* <Link
            to="/cart-page"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <img
              alt="purchase-icon"
              src={process.env.PUBLIC_URL + "/assets/supplier-icon.png"}
            />
            <span className="text-sm font-medium"> cart</span>
          </Link> */}
          {/* <Link
            to="/purchase-detailss"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <img
              alt="purchase-icon"
              src="/assets/supplier-icon.png"
            />
            <span className="text-sm font-medium"> Order details</span>
          </Link> */}
          {/* <Link
            to="/saless"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <img alt="sale-icon" src="/assets/supplier-icon.png" />
            <span className="text-sm font-medium"> Sales</span>
          </Link> */}

          {/* <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
              <Link to="/manage-storee">
                <div className="flex items-center gap-2">
                  <img
                    alt="store-icon"
                    src="/assets/order-icon.png"
                  />
                  <span className="text-sm font-medium"> Manage Store </span>
                </div>
              </Link>
            </summary>
          </details> */}
        </nav>
      </div>

      <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
        <div className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
          <img
            alt="Profile"
            src={localStorageData.imageUrl}
            className="h-10 w-10 rounded-full object-cover"
          />

          <div>
            <p className="text-xs">
              <strong className="block font-medium">
                {localStorageData.firstName + " " + localStorageData.lastName}
              </strong>

              <span> {localStorageData.email} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerSideMenu;
