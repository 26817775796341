import * as React from "react";
const EyeClosed = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      stroke="#252525"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.585 8.587a2 2 0 0 0 2.83 2.828m3.266 3.258A8.717 8.717 0 0 1 10 16c-3.6 0-6.6-2-9-6 1.272-2.12 2.712-3.678 4.32-4.674M8.18 4.18A9.055 9.055 0 0 1 10 4c3.6 0 6.6 2 9 6-.666 1.11-1.379 2.067-2.138 2.87M1 1l18 18"
    />
  </svg>
);
export default EyeClosed;
