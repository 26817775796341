import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import Calendar from "../components/Calendar/Calendar";
import { backend_url } from "../url";

function OrdersDetails() {
  const API = backend_url;
  const navigate = useNavigate();
  const { id } = useParams();
  const [orders, setOrders] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [editedQuantities, setEditedQuantities] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setAllProducts] = useState([]);
  const [currentDueDate, setCurrentDueDate] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchOrderData(id);
  }, [id]);

  useEffect(() => {
    fetchProductsData();
  }, []);

  const fetchProductsData = () => {
    fetch(`${API}/api/product/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (orders) {
      setSelectedStatus(orders.status);
      setSelectedPaymentMode(orders.paymentstatus);
      if (orders?.dueDate) {
        setCurrentDueDate(orders.dueDate);
      }
    }
  }, [orders, orders?.dueDate]);

  const fetchOrderData = (id) => {
    fetch(`${API}/api/order/get/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        initializeEditedQuantities(data);
      })
      .catch((err) => console.log(err));
  };

  const initializeEditedQuantities = (orderData) => {
    const initialQuantities = {};
    orderData.cartItems.forEach((cartItem) => {
      initialQuantities[cartItem.productId[0]._id] = cartItem.quantity;
    });
    setEditedQuantities(initialQuantities);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddProductClick = () => {
    setIsAddingProduct(true);
  };

  const handleCloseModal = () => {
    setIsAddingProduct(false);
    setSelectedProduct(null);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    const updatedQuantities = Object.entries(editedQuantities).map(
      ([productId, quantity]) => ({
        productId,
        quantity,
      })
    );

    fetch(`${API}/api/order/updateorderproduct/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cartItems: updatedQuantities }),
    })
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        setIsEditing(false);
        toast.success("order updated successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setEditedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const handleAddProductToOrder = async (product) => {
    try {
      const response = await fetch(
        `${API}/api/order/addproducttoexistingorder/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cartItems: [{ productId: product._id, quantity: 0 }],
          }),
        }
      );

      if (response.ok) {
        const updatedOrder = await response.json();
        setOrders(updatedOrder);
        toast.success("Product added to order successfully");
        handleCloseModal();
        window.location.reload();
      } else {
        toast.error("Failed to add product to order");
      }
    } catch (error) {
      toast.error("Server error");
    }
  };

  const handleChangeStatus = () => {
    fetch(`${API}/api/order/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedStatus }),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Order status updated successfully");
        navigate("/orders");
      })
      .catch((err) => console.error(err));
  };

  const handlePaymentModeChange = () => {
    fetch(`${API}/api/order/updatePaymentMode/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedPaymentMode }),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Payment mode updated successfully");
      })
      .catch((err) => console.error(err));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-10 flex-col  flex justify-center">
        <div className=" flex flex-col gap-5 w-full">
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-6">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center ">
                <span className="font-bold">Recieved Order Details</span>
              </div>
            </div>
            {orders && (
              <div className="w-11/12 my-4">
                <h1 className="text-2xl font-bold">Customer Details:</h1>
                <div className="flex flex-col gap-2">
                  {orders.customer.map((customerId) => (
                    <div className="flex flex-col" key={customerId}>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">Customer ID:</h5>{" "}
                        {customerId.customerID}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Name:
                        </h5>{" "}
                        {customerId.customerName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Phone Number:
                        </h5>{" "}
                        {customerId.customerPhoneNumber}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Store Name:
                        </h5>{" "}
                        {customerId.storeName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Pincode:
                        </h5>{" "}
                        {customerId.pincode}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Address:
                        </h5>{" "}
                        {customerId.Address}
                      </div>
                      {/* Add more customer details as needed */}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {orders && (
              <div className="w-11/12">
                <h1 className="text-2xl font-bold my-4">
                  Order ID - {orders.orderID}
                </h1>
                <p className="text-sm mb-3 flex ">
                  Order Current Status:{" "}
                  <p className="pl-4 font-bold">{orders.status}</p>
                </p>
                <p className="text-sm mb-3 flex ">
                  Order Payment Status:{" "}
                  <p className="pl-4 font-bold">{orders.paymentstatus}</p>
                </p>
                {currentDueDate !== null &&
                  selectedPaymentMode === "Credit" && (
                    <p className="text-sm mb-3 flex ">
                      Order Payment Due Date:{" "}
                      <p className="pl-4 font-bold">
                        {formatDate(currentDueDate)}
                      </p>
                    </p>
                  )}
                <table className="min-w-full divide-x-2 divide-y-2 border-2 divide-gray-200 border-gray-200 text-sm rounded-md">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900 border-2 border-gray-200">
                        Product Name
                      </th>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900 border-2 border-gray-200">
                        Quantity
                      </th>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900 border-2 border-gray-200">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-x divide-gray-200">
                    {orders &&
                      orders.cartItems.map((cartItem, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-900 border-2 border-gray-200">
                            {cartItem.productId.map((item) => item.name)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700 border-2 border-gray-200">
                            {cartItem.quantity}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700 border-2 border-gray-200">
                            ₹
                            {cartItem.productId.map((item) => item.price) *
                              cartItem.quantity}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-900 border-2 border-gray-200 font-semibold">
                        Total
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700 border-2 border-gray-200 font-semibold">
                        {orders.cartItems.reduce(
                          (acc, cartItem) => acc + cartItem.quantity,
                          0
                        )}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700 border-2 border-gray-200 font-semibold">
                        ₹
                        {orders.cartItems.reduce(
                          (acc, cartItem) =>
                            acc +
                            cartItem.productId.map((item) => item.price) *
                              cartItem.quantity,
                          0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="my-4">
                  <label htmlFor="status" className="mr-3 text-xl font-bold">
                    Order Status:
                  </label>
                  <select
                    className="rounded-lg mr-3"
                    id="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="processing">Processing</option>
                    <option value="order_confirmed">Order Confirmed</option>
                    <option value="shipped">Shipped</option>
                    <option value="out_for_delivery">Out for Delivery</option>
                    <option value="partial_delivery">
                      Partially Delivered
                    </option>
                    <option value="delivered">Delivered</option>
                    <option value="cancelled">cancelled</option>
                  </select>
                  <button
                    className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={handleChangeStatus}
                  >
                    Update Status
                  </button>
                </div>

                {orders && orders.status === "processing" && (
                  <>
                    <button
                      onClick={handleEditClick}
                      className="bg-blue-500 text-white px-4 py-2 mr-4 mt-4 rounded-md"
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleAddProductClick}
                      className="bg-green-500 text-white px-4 py-2 rounded-md"
                    >
                      Add Product
                    </button>
                  </>
                )}
                {isEditing && (
                  <div>
                    {/* Modal for editing quantities */}
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                      <div className="bg-white p-8 rounded-md">
                        <h2 className="text-2xl font-bold mb-4">
                          Edit Quantities
                        </h2>
                        <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {orders.cartItems.map((cartItem) => (
                              <tr key={cartItem.productId[0]._id}>
                                <td>{cartItem.productId[0].name}</td>
                                <td>
                                  <input
                                    type="number"
                                    value={
                                      editedQuantities[
                                        cartItem.productId[0]._id
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        cartItem.productId[0]._id,
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button
                          onClick={handleSaveEdit}
                          className="bg-blue-500 text-white px-4 py-2 pr-4 mr-4 mt-4 rounded-md"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isAddingProduct && (
                  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-md">
                      <h2 className="text-2xl font-bold mb-4">
                        Add Product to Order
                      </h2>

                      {/* Search input */}
                      <input
                        type="text"
                        placeholder="Search Product name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-4 px-4 py-2 rounded-md border"
                      />

                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredProducts.map((product) => (
                            <tr key={product._id}>
                              <td>{product.name}</td>
                              <td>
                                <button
                                  className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                  onClick={() =>
                                    handleAddProductToOrder(product)
                                  }
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        onClick={handleCloseModal}
                        className="bg-red-500 text-white px-4 py-2 mt-4 rounded-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {orders && orders.status === "delivered" && (
                  <div className="my-4">
                    <label
                      htmlFor="paymentMode"
                      className="mr-3 text-xl font-bold"
                    >
                      Payment Mode:
                    </label>
                    <select
                      className="rounded-lg mr-3"
                      id="paymentMode"
                      value={selectedPaymentMode}
                      onChange={(e) => setSelectedPaymentMode(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="UPI">UPI</option>
                      <option value="Cash">Cash</option>
                      <option value="Partial">Partial</option>
                      <option value="Credit">Credit</option>
                    </select>
                    <button
                      className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={handlePaymentModeChange}
                    >
                      Update Payment Mode
                    </button>
                  </div>
                )}
                {selectedPaymentMode === "Credit" && (
                  <Calendar
                    id={id}
                    setCurrentDueDate={setCurrentDueDate}
                    currentDueDate={currentDueDate}
                    selectedPaymentMode={selectedPaymentMode}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-span-12 lg:col-span-10 m-auto flex justify-items-end ">
      <div className=" flex flex-col w-full gap-5">
       <div className="rounded-lg border bg-white border-gray-200 ">

         <CartPage cartItems={cartItems} />
       </div>
      </div>
    </div> */}
    </>
  );
}

export default OrdersDetails;
