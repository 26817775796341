import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { backend_url } from "../url";
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";

function CustomerCareReadOrders() {
  const API = backend_url;
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  console.log(filteredOrders, "filteredorders");
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10); // Number of orders to display per page

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // Function to format the date for comparison
  const formatDateForComparison = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  // Function to format the date
  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };

    return new Intl.DateTimeFormat("en-IN", options).format(date);
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = () => {
    fetch(`${API}/api/order/get`) // Replace with the correct API endpoint
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        setFilteredOrders(data);
      })
      .catch((err) => console.log(err));
  };

  // Function to format the date for comparison
  const formatDateForComparisonforcsv = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const csvHeaders = [
    { label: "Order ID", key: "orderID" },
    { label: "Customer Name", key: "customerName" },
    { label: "Store Name", key: "storeName" },
    { label: "Customer Phone Number", key: "customerPhoneNumber" },
    { label: "Agent Name", key: "agentName" },
    { label: "Platform", key: "platform" },
    { label: "Ordered Date and Time", key: "orderedDateTime" },
    { label: "Order Status", key: "status" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Product Name", key: "productName" },
    { label: "Quantity", key: "quantity" },
    // Add headers for other fields you want to include in the CSV
  ];

  const csvData = orders.flatMap((element) =>
    element.cartItems.map((cartItem) => {
      const productNames = cartItem.productId
        .map((product) => product.name)
        .join(", ");
      return {
        orderID: element.orderID,
        customerName: element.customer
          .map((customer) => customer.customerName)
          .join(", "),
        storeName: element.customer
          .map((customer) => customer.storeName)
          .join(", "),
        customerPhoneNumber: element.customer
          .map((customer) => customer.customerPhoneNumber)
          .join(", "),
        agentName: element.userID.firstName,
        platform: element.platform,
        orderedDateTime: formatDateForComparison(element.createdAt),
        status: element.status,
        paymentStatus: element.paymentstatus,
        productName: productNames,
        quantity: cartItem.quantity,
        // Add data for other fields you want to include in the CSV
      };
    })
  );

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // useEffect(() => {
  //   // Filter orders based on search term
  //   const filtered = orders && orders.filter((element) =>
  //     element.orderID.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     element.customer.some((customer) =>
  //       customer.customerName.toLowerCase().includes(searchTerm.toLowerCase())
  //     ) ||
  //     element.userID.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     element.customer.some((customer) =>
  //       (customer.customerPhoneNumber + '').includes(searchTerm)
  //     ) ||
  //     element.customer.some((customer) =>
  //       customer.storeName.toLowerCase().includes(searchTerm.toLowerCase())
  //     ) ||
  //     element.customer.some((customer) =>
  //       customer.customerID.toLowerCase().includes(searchTerm.toLowerCase())
  //     ) ||
  //     element.customer.some((customer) =>
  //       customer.Address.toLowerCase().includes(searchTerm.toLowerCase())
  //     ) ||
  //     element.customer.some((customer) =>
  //       (customer.pincode + '').includes(searchTerm)
  //     ) ||
  //     element.platform.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     formatDateForComparison(element.createdAt)
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredOrders(filtered);
  //   setCurrentPage(1);
  // }, [searchTerm, orders]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filtered =
      orders &&
      orders.filter(
        (element) =>
          // ... (your existing filtering logic)
          element.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          element.orderID.toLowerCase().includes(searchTerm.toLowerCase()) ||
          element.customer.some((customer) =>
            customer.customerName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) ||
          element.userID.firstName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          element.customer.some((customer) =>
            (customer.customerPhoneNumber + "").includes(searchTerm)
          ) ||
          element.customer.some((customer) =>
            (customer.pincode + "").includes(searchTerm)
          ) ||
          element.customer.some((customer) =>
            customer.storeName.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          element.platform.toLowerCase().includes(searchTerm.toLowerCase()) ||
          formatDateForComparison(element.createdAt)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to the first page when the data changes
  }, [searchTerm, orders]);

  return (
    <>
      <div className="col-span-12 lg:col-span-10 flex-col  flex justify-center">
        <div className=" flex flex-col gap-5 w-full">
          {/* Table  */}
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-6">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center ">
                <span className="font-bold">Recieved Orders</span>
                <div className="flex justify-center items-center px-2 border-2 rounded-md ">
                  <img
                    alt="search-icon"
                    className="w-5 h-5"
                    src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full h-full px-2 border-none outline-none"
                  />
                </div>
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={"orders.csv"}
                  className="px-3 py-2 text-xs font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none"
                >
                  Export CSV
                </CSVLink>
              </div>
              {/* <div className="flex gap-4">
              
            </div> */}
            </div>
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Order ID
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Customer Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Store Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Customer Ph.no
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Agent Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Platform
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Ordered Date and Time
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Order Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Payment Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {currentOrders &&
                  currentOrders.map((element, index) => {
                    return (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                          {element.orderID}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                          {element.customer.map(
                            (customer) => customer.customerName
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.customer.map(
                            (customer) => customer.storeName
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.customer.map(
                            (customer) => customer.customerPhoneNumber
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.userID.firstName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.platform}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {formatDate(new Date(element.createdAt))}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-[#7303fc] font-bold">
                          {element.status}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 font-bold">
                          {element.status.toLowerCase() === "cancelled"
                            ? "Cancelled"
                            : element.paymentstatus}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          <Link
                            to={`/order-detailssearch=order/${element._id}`}
                          >
                            <button
                              type="button"
                              className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              View Order
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="flex justify-end mt-4">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={ordersPerPage}
                totalItemsCount={filteredOrders.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                activeClass="z-10 bg-indigo-500 text-white border-indigo-500"
                itemClassFirst="mr-0"
                itemClassPrev="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                itemClassNext="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                itemClassLast="ml-0"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerCareReadOrders;
