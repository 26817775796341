import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-datepicker/dist/react-datepicker.css";
import { backend_url } from "../url";

function Orders() {
  const API = backend_url;
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // Function to format the date for comparison
  const formatDateForComparison = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  // Function to format the date
  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };

    return new Intl.DateTimeFormat("en-IN", options).format(date);
  };
  const fetchOrderData = () => {
    fetch(`${API}/api/order/get`)
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        setFilteredOrders(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  // Function to format the date for comparison
  const formatDateForComparisonforcsv = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const csvHeaders = [
    { label: "Order ID", key: "orderID" },
    { label: "Customer Name", key: "customerName" },
    { label: "Store Name", key: "storeName" },
    { label: "Customer Phone Number", key: "customerPhoneNumber" },
    { label: "Agent Name", key: "agentName" },
    { label: "Platform", key: "platform" },
    { label: "Ordered Date and Time", key: "orderedDateTime" },
    { label: "Order Status", key: "status" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Product Name", key: "productName" },
    { label: "Quantity", key: "quantity" },
  ];

  const csvData = filteredOrders.flatMap((element) =>
    element.cartItems.map((cartItem) => {
      const productNames = cartItem.productId
        .map((product) => product.name)
        .join(", ");
      return {
        orderID: element.orderID,
        customerName: element.customer
          .map((customer) => customer.customerName)
          .join(", "),
        storeName: element.customer
          .map((customer) => customer.storeName)
          .join(", "),
        customerPhoneNumber: element.customer
          .map((customer) => customer.customerPhoneNumber)
          .join(", "),
        agentName: element.userID.firstName,
        platform: element.platform,
        orderedDateTime: formatDateForComparison(element.createdAt),
        status: element.status,
        paymentStatus: element.paymentstatus,
        productName: productNames,
        quantity: cartItem.quantity,
      };
    })
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filtered =
      orders &&
      orders.filter(
        (element) =>
          element.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          element.paymentstatus
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          element.orderID.toLowerCase().includes(searchTerm.toLowerCase()) ||
          element.customer.some((customer) =>
            customer.customerName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) ||
          element.userID.firstName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          element.customer.some((customer) =>
            (customer.customerPhoneNumber + "").includes(searchTerm)
          ) ||
          element.customer.some((customer) =>
            (customer.pincode + "").includes(searchTerm)
          ) ||
          element.customer.some((customer) =>
            customer.storeName.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          element.customer.some((customer) =>
            customer.Address.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          element.platform.toLowerCase().includes(searchTerm.toLowerCase()) ||
          formatDateForComparison(element.createdAt)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );

    const filteredByDate =
      filterStartDate && filterEndDate
        ? filtered.filter((element) => {
            const orderDate = new Date(element.createdAt);
            return orderDate >= filterStartDate && orderDate <= filterEndDate;
          })
        : filtered;
    setFilteredOrders(filteredByDate);
    setCurrentPage(1);
  }, [searchTerm, orders, filterStartDate, filterEndDate]);

  const exportPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "a4",
    });

    const logo = "/assets/adhrija-logo.png";

    const headers = csvHeaders.map((header) => header.label);
    const data = csvData.map((row) =>
      csvHeaders.map((header) => row[header.key])
    );

    doc.autoTable({
      head: [headers],
      body: data,
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const imgWidth = 54;
        const imgHeight = 32;
        const xPos = 40;
        const yPos = 16;

        doc.addImage(logo, "PNG", xPos, yPos, imgWidth, imgHeight);
        doc.addFont("Helvetica", "semiBold");
        doc.setFontSize(12);
        doc.text("Adhrija", 100, 30);
        doc.text("Every Grains Says Something", 100, 44);
        doc.setFontSize(11);
        doc.text("+91 9035016115", pageWidth - 42, 24, {
          align: "right",
        });
        doc.text("https://adhrija.com", pageWidth - 42, 37, {
          align: "right",
        });
        doc.text("adhrijaindia@gmail.com", pageWidth - 42, 52, {
          align: "right",
        });
        doc.setFontSize(9.5);
        const year = new Date().getFullYear();
        doc.text(`© Copyright ${year} by Adhrija`, 420, pageHeight - 20, {
          align: "center",
        });
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        data.settings.margin.top = 66;
      },
      margin: { top: 66 },
      styles: {
        fontSize: 10,
      },
      columnStyles: {
        0: { cellWidth: 70 }, // Order ID
        1: { cellWidth: 80 }, // Customer Name
        2: { cellWidth: 80 }, // Store Name
        3: { cellWidth: 65 }, // Customer Phone Number
        4: { cellWidth: 60 }, // Agent Name
        5: { cellWidth: 50 }, // Platform
        6: { cellWidth: 55 }, // Ordered Date and Time
        7: { cellWidth: 60 }, // Order Status
        8: { cellWidth: 50 }, // Payment Status
        9: { cellWidth: 140 }, // Product Name
        10: { cellWidth: 50 }, // Quantity
      },
      rowPageBreak: "avoid",
      minCellHeight: 20,
    });

    doc.save("orders.pdf");
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-10 flex-col flex justify-center">
        <div className="flex flex-col gap-5 w-full">
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-6 min-h-screen">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center">
                <span className="font-bold">Received Orders</span>
                <div className="flex justify-center items-center px-2 border-2 rounded-md">
                  <img
                    alt="search-icon"
                    className="w-5 h-5"
                    src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full h-full px-2 border-none outline-none focus:ring-0"
                  />
                </div>
                <div className="flex gap-1">
                  <div className="custom-datepicker mr-3 relative">
                    <DatePicker
                      selected={filterStartDate}
                      onChange={(date) => {
                        setFilterStartDate(date);
                      }}
                      placeholderText="Start Date"
                    />
                    <div className="absolute right-3 top-3 pointer-events-none">
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <div className="custom-datepicker mr-3 relative">
                    <DatePicker
                      selected={filterEndDate}
                      onChange={(date) => {
                        setFilterEndDate(date);
                      }}
                      placeholderText="End Date"
                    />
                    <div className="absolute right-3 top-3 pointer-events-none">
                      <FaCalendarAlt />
                    </div>
                  </div>
                </div>
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={"orders.csv"}
                  className="px-3 py-2 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none transition-all duration-300"
                >
                  Export CSV
                </CSVLink>
                <button
                  className="px-3 py-2 text-sm font-medium text-center text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none transition-all duration-300"
                  onClick={() => exportPDF()}
                >
                  Export PDF
                </button>
              </div>
            </div>
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Order ID
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Customer Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Store Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Customer Ph.no
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Agent Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Platform
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Ordered Date and Time
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Order Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Payment Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentOrders &&
                  currentOrders.map((element) => {
                    return (
                      <tr key={element._id}>
                        <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                          {element.orderID}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                          {element.customer.map(
                            (customer) => customer.customerName
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.customer.map(
                            (customer) => customer.storeName
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.customer.map(
                            (customer) => customer.customerPhoneNumber
                          )}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.userID.firstName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.platform}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {formatDate(new Date(element.createdAt))}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-[#7303fc] font-bold">
                          {element.status}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 font-bold">
                          {element.status.toLowerCase() === "cancelled"
                            ? "Cancelled"
                            : element.paymentstatus}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          <Link to={`/order-details/${element._id}`}>
                            <button
                              type="button"
                              className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              View Order
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="flex justify-end mt-4">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={ordersPerPage}
                totalItemsCount={filteredOrders.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                activeClass="z-10 bg-indigo-500 text-white border-indigo-500"
                itemClassFirst="mr-0"
                itemClassPrev="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                itemClassNext="relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                itemClassLast="ml-0"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Orders;
