import React, { useEffect, useState } from "react";
import { backend_url } from "../url";

function AdminPlaceOrder() {
  const API = backend_url;
  const [category, setCategory] = useState("");
  const [categories, setAllCategoryData] = useState([]);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  useEffect(() => {
    fetchCategoryData();
  }, []);

  //   // Fetching Data of All Purchase items
  const fetchCategoryData = () => {
    fetch(`${API}/api/category/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllCategoryData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (category) {
      fetchProductsByCategory();
    }
  }, [category]);

  // Fetch products based on the selected category
  const fetchProductsByCategory = () => {
    fetch(`${API}/api/category/get/${category}`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => console.log(err));
  };

  const isProductInCart = (productId) => {
    return cartItems.some((item) => item._id === productId);
  };

  const markAsAdded = (element) => {
    // This function will handle marking the item as added to the cart
    // You can customize this based on your requirements.
    return (
      <span role="img" aria-label="Added to Cart" style={{ color: "green" }}>
        ✔️
      </span>
    );
  };

  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center">
      <div className="flex flex-col gap-5 w-11/12">
        <div className="flex justify-between pt-5 pb-3 px-3">
          <div className="flex gap-4 justify-center items-center">
            <span className="font-bold">
              Select a category to view products:
            </span>
            <select
              className="rounded-lg"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select a Category</option>
              {categories.map((element) => (
                <option key={element._id} value={element._id}>
                  {element.categoryName}
                </option>
              ))}
            </select>
          </div>
        </div>
        {category && (
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center ">
                <span className="font-bold">Products</span>
              </div>
            </div>

            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Products
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Product Image
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Price
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Brand
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Stock
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Availibility
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {products &&
                  products.product &&
                  products.product.map((element) => {
                    const addToCart = () => {
                      setCartItems((prevItems) => [...prevItems, element]);
                      localStorage.setItem(
                        "cartItems",
                        JSON.stringify([...cartItems, element])
                      );
                    };

                    return (
                      <tr key={element._id}>
                        <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                          {element.name}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          <img
                            src={element.imageUrl} // Assuming there's an 'image' property in your product object
                            alt={element.name}
                            className="w-15 h-14" // Adjust the width and height as needed
                          />
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.price}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.manufacturer}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.stock}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {element.stock > 0 ? "In Stock" : "Not in Stock"}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {/* {isProductInCart(element._id) ? (
                            markAsAdded(element)
                          ) : element.stock > 0 ? (
                            <button
                              type="button"
                              className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover-bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => addToCart(element)}
                            >
                              Add to Cart
                            </button>
                          ) : (
                            <span className="text-red-500">Out of Stock</span>
                          )} */}
                          {isProductInCart(element._id) ? (
                            markAsAdded(element)
                          ) : (
                            <button
                              type="button"
                              className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover-bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => addToCart(element)}
                            >
                              Add to Cart
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPlaceOrder;
