import React, { useContext, useEffect, useState } from "react";
import { backend_url } from "../url";
import AuthContext from "../AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const API = backend_url;
  const navigate = useNavigate();
  const [userPassword, setUserPassword] = useState(null);
  const auth = useContext(AuthContext);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordCorrect, setOldPasswordCorrect] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [oldPasswordEntered, setOldPasswordEntered] = useState(false);

  useEffect(() => {
    // If the user is authenticated (you might have a token or user ID)
    if (auth.user) {
      // Make an API request to fetch the user's password from your backend
      fetch(`${API}/api/user/get/${auth.user}`)
        .then((response) => response.json())
        .then((data) => {
          setUserPassword(data.password); // Assuming you receive the user's password in the response
        })
        .catch((error) => {
          console.error("Error fetching user password:", error);
          setUserPassword(null);
        });
    }
  }, [auth.user]);

  useEffect(() => {
    if (oldPassword === userPassword) {
      setOldPasswordCorrect(true);
      setOldPasswordError("");
    } else {
      setOldPasswordCorrect(false);
      setOldPasswordError("Incorrect old password");
    }
  }, [oldPassword, userPassword]);

  useEffect(() => {
    // Check if the new password and confirm password match
    if (newPassword === confirmPassword) {
      setPasswordsMatch(true);
      setConfirmPasswordError("");
    } else {
      setPasswordsMatch(false);
      setConfirmPasswordError("passwords do not match");
    }
  }, [newPassword, confirmPassword]);

  const handleTogglePassword = (passwordType) => {
    switch (passwordType) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);

  useEffect(() => {
    setIsUpdateButtonDisabled(!oldPasswordCorrect || !passwordsMatch);
  }, [oldPasswordCorrect, passwordsMatch]);

  const handleUpdatePassword = () => {
    fetch(`${API}/api/user/updatepassword/${auth.user}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newPassword }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        toast.success("Password Updated Successfully");
        alert("Password Updated");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error updating password:", error);
      });
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-10  flex justify-center">
        <div className=" flex flex-col gap-5 w-11/12">
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200">
            <div className="flex justify-center pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center text-center">
                <span className="font-bold text-center">Change Password</span>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 pb-8 pt-4">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center "></div>
            </div>

            {/* Old Password Input */}
            <div className="relative w-1/4 container mx-auto mt-5">
              <input
                type={showOldPassword ? "text" : "password"}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setOldPasswordEntered(!!e.target.value);
                }}
                className="w-full px-4 py-2 text-base border border-gray-300 rounded outline-none focus:ring-blue-500 focus:border-blue-500 focus:ring-1"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                onClick={() => handleTogglePassword("old")}
              >
                {showOldPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {oldPasswordEntered && oldPasswordError && (
                <p className="text-red-500">{oldPasswordError}</p>
              )}
            </div>

            {/* New Password Input */}
            <div className="relative w-1/4 container mx-auto mt-5">
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-2 text-base border border-gray-300 rounded outline-none focus:ring-blue-500 focus:border-blue-500 focus:ring-1"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                onClick={() => handleTogglePassword("new")}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="relative w-1/4 container mx-auto mt-5">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 text-base border border-gray-300 rounded outline-none focus:ring-blue-500 focus:border-blue-500 focus:ring-1"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                onClick={() => handleTogglePassword("confirm")}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {confirmPasswordError && (
                <p className="text-red-500">{confirmPasswordError}</p>
              )}
            </div>

            <div className="relative w-1/4 container mx-auto mt-5">
              <button
                className="mt-5 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                onClick={handleUpdatePassword}
                disabled={isUpdateButtonDisabled}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
