import React from "react";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./components/Layout";
import Inventory from "./pages/Inventory";
import NoPageFound from "./pages/NoPageFound";
import AuthContext from "./AuthContext";
import ProtectedWrapper from "./ProtectedWrapper";
import { useEffect, useState } from "react";
// import Store from "./pages/Store";
import Sales from "./pages/Sales";
import PurchaseDetails from "./pages/PurchaseDetails";
import SalesManagerLayout from "./components/SalesManagerLayout";
import SalesDashboard from "./pages/SalesDashboard";
import SalesRegister from "./pages/SalesRegister";
import Category from "./pages/Category";
import Order from "./pages/Order";
import CartPage from "./pages/CartPage";
// import CartPage2 from "./pages/Cart";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import AdminCustomers from "./pages/AdminCustomers";
import OrdersDetails from "./pages/OrderDetails";
import LowstockProducts from "./components/LowstockProducts";
import AdminPlaceOrder from "./pages/AdminPlaceOrder";
import CartPage2 from "./pages/Cart";
import CustomerCareLayout from "./components/CustomerCareLayout";
import CustomerCareDashboard from "./pages/CustomerCareDashboard";
import CustomerCareReadOrders from "./pages/CustomerCareReadOrders";
import OrderDetailsForCustomerCare from "./pages/OrderDetailsForCustomerCare";
import CustomerCareRegister from "./pages/CustomerCareRegister";
import ChangePassword from "./pages/ChangePassword";
import OrdersByUserId from "./pages/OrdersByUserId";
import OrderDetailsForSales from "./pages/OrderDetailsforSales";

const App = () => {
  const [user, setUser] = useState([]);
  const [loader, setLoader] = useState(true);
  let myLoginUser = JSON.parse(localStorage.getItem("user"));
  // console.log("USER: ",user)

  useEffect(() => {
    if (myLoginUser) {
      setUser(myLoginUser._id);
      setLoader(false);
    } else {
      setUser("");
      setLoader(false);
    }
  }, [myLoginUser]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  let value = { user, signin, signout };

  if (loader)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>LOADING...</h1>
      </div>
    );

  return (
    <AuthContext.Provider value={value}>
      <BrowserRouter>
        <Toaster position="top-right" toastOptions={{ duration: 4000 }} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/adhrija=admin-sales-register"
            element={<SalesRegister />}
          />
          <Route
            path="/customercare-register"
            element={<CustomerCareRegister />}
          />

          {/* <ToastContainer /> */}
          {/* Admin routes start here */}
          <Route
            path="/"
            element={
              <ProtectedWrapper requiredRole="Admin">
                <Layout />
              </ProtectedWrapper>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/purchase-details" element={<PurchaseDetails />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/category" element={<Category />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/customer" element={<AdminCustomers />} />
            <Route path="/order-details/:id" element={<OrdersDetails />} />
            <Route path="/notification" element={<LowstockProducts />} />
            <Route path="/place-order" element={<AdminPlaceOrder />} />
            <Route path="/cart" element={<CartPage2 />} />
            <Route path="/password=new-password" element={<ChangePassword />} />
            <Route path="/orders-by-userid" element={<OrdersByUserId />} />
          </Route>
          {/* Admin routes ends here */}

          {/* Sales routes start here */}
          <Route
            path="/"
            element={
              <ProtectedWrapper requiredRole="Sales">
                <SalesManagerLayout />
              </ProtectedWrapper>
            }
          >
            <Route path="/sales-dashboard" element={<SalesDashboard />} />
            <Route path="/order" element={<Order />} />
            <Route path="/cart-page" element={<CartPage />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/myorders" element={<OrdersByUserId />} />
            <Route
              path="/userorderdetails/:id"
              element={<OrderDetailsForSales />}
            />
          </Route>
          {/* Sales routes ends here */}

          {/* customrer care routes starts here */}
          <Route
            path="/"
            element={
              <ProtectedWrapper requiredRole="CustomerCare">
                <CustomerCareLayout />
              </ProtectedWrapper>
            }
          >
            <Route
              path="customercare-dashboard"
              element={<CustomerCareDashboard />}
            />
            <Route path="allorders" element={<CustomerCareReadOrders />} />
            <Route
              path="order-detailssearch=order/:id"
              element={<OrderDetailsForCustomerCare />}
            />
          </Route>
          {/* customrer care routes ends here */}

          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
