import * as React from "react";
const Eye = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={14} fill="none">
    <path
      stroke="#252525"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 7a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
    />
    <path
      stroke="#252525"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 7c-2.4 4-5.4 6-9 6-3.6 0-6.6-2-9-6 2.4-4 5.4-6 9-6 3.6 0 6.6 2 9 6Z"
    />
  </svg>
);
export default Eye;
