import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backend_url } from "../url";

function OrderDetailsForCustomerCare() {
  const API = backend_url;
  const { id } = useParams();
  const [orders, setOrders] = useState();

  useEffect(() => {
    fetchOrderData(id);
  }, [id]);

  const fetchOrderData = (id) => {
    fetch(`${API}/api/order/get/${id}`) // Replace with the correct API endpoint
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-10 flex-col  flex justify-center">
        <div className=" flex flex-col gap-5 w-full">
          {/* Table  */}
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-6">
            <div className="flex justify-between pt-5 pb-3 px-3">
              <div className="flex gap-4 justify-center items-center ">
                <span className="font-bold">Recieved Orders</span>
                <div className="flex justify-center items-center px-2 border-2 rounded-md hidden">
                  <img
                    alt="search-icon"
                    className="w-5 h-5"
                    src={process.env.PUBLIC_URL + "/assets/search-icon.png"}
                  />
                  <input
                    className="border-none outline-none focus:border-none text-xs"
                    type="text"
                    placeholder="Search here"
                    //   value={searchTerm}
                    //   onChange={handleSearchTerm}
                  />
                </div>
              </div>
              {/* <div className="flex gap-4">
              
            </div> */}
            </div>
            {orders && (
              <div className="w-11/12 my-4">
                <h1 className="text-2xl font-bold">Customer Details:</h1>
                <div className="flex flex-col gap-2">
                  {/* <strong className="font-bold">Customer Details:</strong> */}
                  {orders.customer.map((customerId) => (
                    // Assuming you have a separate API endpoint to fetch customer details
                    // Modify the API endpoint accordingly
                    <div className="flex flex-col" key={customerId}>
                      {/* Fetch and display customer details here */}
                      {/* Example: */}
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">Customer ID:</h5>{" "}
                        {customerId.customerID}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Name:
                        </h5>{" "}
                        {customerId.customerName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Phone Number:
                        </h5>{" "}
                        {customerId.customerPhoneNumber}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Store Name:
                        </h5>{" "}
                        {customerId.storeName}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Pincode:
                        </h5>{" "}
                        {customerId.pincode}
                      </div>
                      <div className="flex ">
                        <h5 className="text-md font-bold pr-3">
                          Customer Address:
                        </h5>{" "}
                        {customerId.Address}
                      </div>
                      {/* Add more customer details as needed */}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {orders && (
              <div className="w-11/12">
                <h1 className="text-2xl font-bold my-4">
                  Order ID - {orders.orderID}
                </h1>
                <p className="text-sm mb-3 flex ">
                  Order Current Status:{" "}
                  <p className="pl-4 font-bold">{orders.status}</p>
                </p>
                <p className="text-sm mb-3 flex ">
                  Order Payment Status:{" "}
                  <p className="pl-4 font-bold">{orders.paymentstatus}</p>
                </p>
                <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                        Product Name
                      </th>
                      <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {orders &&
                      orders.cartItems.map((cartItem, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                            {/* {cartItem.productId[0].name} Assuming 'name' is the property for the product name */}
                            {cartItem.productId.map(
                              (customer) => customer.name
                            )}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            {cartItem.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-span-12 lg:col-span-10 m-auto flex justify-items-end ">
      <div className=" flex flex-col w-full gap-5">
       <div className="rounded-lg border bg-white border-gray-200 ">

         <CartPage cartItems={cartItems} />
       </div>
      </div>
    </div> */}
    </>
  );
}

export default OrderDetailsForCustomerCare;
